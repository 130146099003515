import React from "react"
import Layout from "../../components/Layout/Layout"
import Header from "../../components/Layout/Header"
import Seo from "../../components/Layout/Seo"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

export default function index({ pageContext }) {
    const {
        breadcrumb: { crumbs },
    } = pageContext
    return (
        <Layout>
            <Header />
            <div className="max-w-screen-md m-auto py-16 px-6 min-h-screen">
                <div className="mb-12">
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbSeparator=" / "
                        crumbLabel={"プライバシーポリシー"}
                    />
                </div>
                <div className="flex ">
                    <h1 className="text-2xl markerTitle inline-block text-center m-auto font-gothic">プライバシーポリシー</h1>
                </div>

                <div className="mt-12 entry-content">
                    <p className="mb-4">
                        当サイトは、以下のとおり「プライバシーポリシー」を定め、当サイト訪問者の個人情報の保護に努めます。
                    </p>

                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">Cookieの使用について</h2>
                    <p>当サイトでは、広告配信やアクセス解析のためにCookieを使用しています。Cookieによりブラウザを識別していますが、特定の個人の識別はできない状態で匿名性が保たれています。Cookieの使用を望まない場合、ブラウザからCookieを無効に設定できます。</p>
                    
                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">広告について</h2>
                    <p>当サイトでは、第三者配信の広告サービス（Googleアドセンス、もしもアフィリエイト、楽天アフィリエイト、afb、リンクシェアアフィリエイト）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。Cookieを無効にする方法やGoogleアドセンスに関する詳細は <a href="https://policies.google.com/technologies/ads?gl=jp" target="_blank" rel="noopener noreferrer">「広告 – ポリシーと規約 – Google」</a> をご確認ください。</p>

                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">アクセス解析ツールについて</h2>
                    <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
                    

                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">免責事項</h2>
                    <p>当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。また当サイトのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>



                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">著作権</h2>
                    <p>当サイトに掲載されている文章・画像の著作権は、運営者に帰属しています。法的に認められている引用の範囲を超えて、無断で転載することを禁止します。</p>

                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">プライバシーポリシーの変更</h2>
                    <p>当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直して改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。</p>
                    <p>制定日:2024年1月20日<br />
                        改定日:2024年1月20日</p>
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title={"プライバシーポリシー"} description={"当サイトは、以下のとおり「プライバシーポリシー」を定め、当サイト訪問者の個人情報の保護に努めます。"} />
